import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import { Modal, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ModalEditor from './ModalAccept';
import { OvertimeDisplay } from '../../../components/OvertimeDisplay/OvertimeDisplay';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';
import GPSInfo from './GPSInfo';

// Elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';

//services
import { serverTimeFormat } from '~/library/core';

//utils
import statuses from '~/utils/normalStatuses';
import { shortDateFormat, timeFormat, totalTimeFormat } from '~/utils/dateFormat';
import customerFeature from '~/utils/customerFeature';
import { minutesToTimeInput } from '~/utils/timeCalc';
import { statusMap } from '~/utils/normalStatuses';
import { getIntegrationIconWithStatus } from '~/utils/integrationUtils';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

class TimelogsList extends Component {
    constructor(props) {
        super(props)
        const page = (this.props.router.location.state && this.props.router.location.state.page) ? this.props.router.location.state.page : 0;
        this.state = {
            accepting: null,
            acceptanceChanged: false,
            selected: null,
            isUserUploadedImg: false,
            userImageAttachmentId: null,
            isTipModal: false,
            tipAmount: '',
            isLoading: true,
            isPageAddedOrEdited: page ? true : false,
            page: page ? page : 0,
        }
        this.renderExpandedContent = this.renderExpandedContent.bind();
        this.rowHasAnomaly = this.rowHasAnomaly.bind();
        this.handleExpandAllSubRows = this.handleExpandAllSubRows.bind();
        this.goBack = this.goBack.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    };

    //this method is replacing the URL pushing part while add/edit//
    goBack = (page = 0) => {
        this.setState({ page, isPageAddedOrEdited: true });
    }

    //method to maintain react-table page in parent component//
    handlePageChange = (page, isEdit) => {
        this.setState({ page: page, isPageAddedOrEdited: isEdit });
    }

    handleTableButtonAction = (id, type) => {
        if (type === 'edit') this.props.router.navigate(`/admin/timelogs/${id}`, { state: { timelogs: id, page: this.state.page } });
        if (type === 'remove') {
            return this.props.handleDelete(id, (id) => {
                return this.props.timelogStore.remove(id).then((res) => {
                    this.props.timelogStore.resetLastListLoadTime(new Date());
                    return res;
                });
            });
        }
    };

    alreadyProcessedCols = false;

    cols = [
        {
            Header: 'Project',
            id: 'project_name',
            accessor: ({ is_holiday, project_name, project_number }) => (
                <span>{is_holiday ? this.props.t('holiday') : `${project_number || ''} | ${project_name || ''}`}</span>
            ),
            maxWidth: 95,
            minWidth: 80,
            isSortable: true,
        },
        {
            Header: 'Task',
            id: 'tasks.name',
            accessor: ({ is_holiday, task_name, task_number }) => (
                <span>{is_holiday ? this.props.t('holiday') : `${task_number || ''} | ${task_name || ''}`}</span>
            ),
            maxWidth: 95,
            minWidth: 80,
            isSortable: true,
        },
        {
            Header: 'Actions',
            id: 'actions',
            sortable: false,
            accessor: (full) => this.renderTableActionButtons(full.id, full),
            maxWidth: 210,
            minWidth: 200,
        },
    ];

    componentDidMount() {
        const { commonStore, userStore, t } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data &&
            commonStore.config.client.data.dateTimeRules
            ? commonStore.config.client.data.dateTimeRules : false;
        const showAnomalies = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.loginRules
            && commonStore.config.client.data.loginRules.trackAnomalies;
        this.cols.splice(
            this.cols.length - 1,
            0,
            {
                Header: 'Date',
                id: 'for_date',
                accessor: ({ for_date, from, to, status }) => <span className='timelog-date'>{from > to && status !== 'draft' ? `${for_date && shortDateFormat(for_date, dateTimeRules)} | ${shortDateFormat(moment(for_date).add(1, 'days'), dateTimeRules)}` : `${for_date && shortDateFormat(for_date, dateTimeRules)}`}</span>,
                maxWidth: 82,
                minWidth: 70,
            },
            {
                Header: 'Time',
                id: 'from',
                accessor: ({ from, to, status, anomaly, data }) => (
                    <span className={showAnomalies && this.rowHasAnomaly({ anomaly }) && !data.toChangedById ? 'cursor-pointer' : ''}>
                        {showAnomalies && this.rowHasAnomaly({ anomaly }) && !data.toChangedById && <i className='fa fa-exclamation text-danger' />}
                        {timeFormat(from, dateTimeRules)} - {status === 'draft' ? '...' : timeFormat(to, dateTimeRules)}
                    </span>
                ),
                maxWidth: 130,
                minWidth: 130,
            }
        );

        this.cols.splice(this.cols.length - 1, 0, {
            Header: 'GPS/Status',
            id: 'gps_data',
            sortable: false,
            accessor: (row) => <GPSInfo status={row.status} data={row.gps_data} full={row} />,
            maxWidth: 110,
            minWidth: 80,
        });
        this.cols.splice(
            this.cols.length - 1,
            0,
            {
                Header: 'Total Hours',
                id: 'total_hours_normal',
                accessor: ({ total_hours_normal, total_hours_overtime }) => <span>{totalTimeFormat(parseInt(total_hours_overtime) ? total_hours_overtime + total_hours_normal : total_hours_normal, dateTimeRules)}</span>,
                maxWidth: 70,
                minWidth: 60,
            },
            {
                Header: 'Overtime',
                id: 'total_hours_overtime',
                accessor: ({ data, dateTimeRules, _total_hours_overtime }) => (
                    <OvertimeDisplay
                        data={data ? data.overtimes : null}
                        dateTimeRules={dateTimeRules}
                        config={commonStore.config}
                        _total_hours_overtime={_total_hours_overtime}
                        t={t}
                    />
                ),
                maxWidth: 70,
                minWidth: 60,
            }
        );
        if (!this.alreadyProcessedCols) {
            if (userStore.currentUser.user_type !== 'member') {
                this.cols.splice(0, 0, {
                    Header: 'Member',
                    id: 'users.first_name',
                    accessor: ({ first_name, last_name }) => <span>{first_name + ' ' + last_name}</span>,
                    maxWidth: 140,
                    minWidth: 80,
                    isSortable: true,
                    textAlign: 'start',
                });
            }
            this.alreadyProcessedCols = true;
        }
        this.filterByRedirection();
    }

    filterByRedirection = () => {
        const dashboardRedirected = this.props.router.location?.state?.dashboardRedirected
        if (!dashboardRedirected) return this.setState({ isLoading: false })
        if (dashboardRedirected === 'active') {
            this.props.timelogStore.setFilter("fromDate", `${moment(new Date()).add(-1, 'days').format('YYYY-MM-DD')}`) // yesterday
            this.props.timelogStore.setFilter("toDate", `${moment(new Date()).format('YYYY-MM-DD')}`) //today
            this.props.timelogStore.setFilter("fromDate1", new Date(moment(new Date()).add(-1, 'days').format('YYYY-MM-DD')))
            this.props.timelogStore.setFilter("toDate1", new Date())
        }
        this.props.timelogStore.setFilter("status", `${dashboardRedirected}`)
        this.props.timelogStore.onFilter()
        this.setState({ isLoading: false })
    }

    handleAccept(id, full) {
        const {commonStore,userStore,t} = this.props;
        const {currentUser} = userStore;
        if(currentUser.id == full.user_id)
        {
            return commonStore.addNotification(t("Can't approve own working hours"), null, 'error');            
        }
        if (full.status == 'approved' && !full.validateTimetrackOption) return;
        this.setState({
            accepting: {
                ids: [id],
                count: 1,
                entity: 'timelog',
                startedByWorklogButton: true,
            },
        });
    }

    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }

    onMassAccept() {
        const ids = [];
        if (!this.state.selected) {
            this.props.commonStore.addNotification(this.props.t('Choose items'), null, 'error');
            return;
        }
        Object.keys(this.state.selected).forEach((id) => {
            if (this.state.selected[id]) ids.push(id);
        });
        this.setState({
            acceptanceChanged: false,
            accepting: {
                ids,
                count: ids.length,
                entity: 'timelog',
            },
        });
    }

    afterMassAccept() {
        this.handlePageChange(this.state.page, true);
        this.props.timelogStore.resetLastListLoadTime(new Date());
        this.setState({ accepting: null, acceptanceChanged: true });
    }

    renderTableActionButtons = (id, full) => {
        const { t, commonStore } = this.props;
        const { config } = commonStore;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const showStatusBlock = customerFeature('allow_accepting');
        const hideEditWhenAccepted = customerFeature('timelog_no_edit_for_approved');
        const hideEditWhenNotDraft = customerFeature('timelog_no_edit_for_done');
        let hideEdit = hideEditWhenAccepted && full.status === 'approved' && user_type === 'member';
        if (hideEditWhenNotDraft && full.status !== 'draft' && user_type === 'member') {
            hideEdit = true;
        }
        const hideDeleteButton =  (this.props.commonStore.config.client && this.props.commonStore.config.client.data 
                                    && this.props.commonStore.config.client.data.basicRules &&  
                                    this.props.commonStore.config.client.data.basicRules.disableDeleteTimelogWhenApproved  ? 
                                    this.props.commonStore.config.client.data.basicRules.disableDeleteTimelogWhenApproved : false )
        const hideDelete = user_type === 'member' && hideDeleteButton && full.status == 'approved';
       
        const showStatus = (user_type === 'pm' || user_type == 'admin') && customerFeature('allow_accepting');
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        let statusText = full.status;
        if (!statusText) statusText = 'active';
        statusText = statusMap[statusText] || '';
        const actionable = showStatus && full.status !== 'approved';
        let timetrackValidatePopupText = [];
        if (full.validateTimetrackOption) {
            if (!full.startVariationNumber && !full.endVariationNumber) {
                timetrackValidatePopupText.push(t('Arrived and left in time'));
            } else {
                if (full.startVariationNumber) {
                    if (full.startVariationNumber < 0) {
                        timetrackValidatePopupText.push(
                            `${t('Arrived')} ${minutesToTimeInput(-full.startVariationNumber)} ${t('later')}`
                        );
                    } else {
                        timetrackValidatePopupText.push(
                            `${t('Arrived')} ${minutesToTimeInput(full.startVariationNumber)} ${t('earlier')}`
                        );
                    }
                }
                if (full.endVariationNumber) {
                    if (full.endVariationNumber < 0) {
                        timetrackValidatePopupText.push(
                            `${t('Left')} ${minutesToTimeInput(-full.endVariationNumber)} ${t('earlier')}`
                        );
                    } else {
                        timetrackValidatePopupText.push(
                            `${t('Left')} ${minutesToTimeInput(full.endVariationNumber)} ${t('later')}`
                        );
                    }
                }
            }
        }
        return (
            <div className="actions-center">
                {getIntegrationIconWithStatus(config, full, t)}
                {full.status !== 'draft' && showStatusBlock && (
                    <OverlayTrigger
                        key="timesheet_status"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {[t(statusText), ...timetrackValidatePopupText].map((text) => (
                                    <>
                                        {text}
                                        <br />
                                    </>
                                ))}
                            </Tooltip>
                        }
                    >
                        <div
                            className={`status-block status-${full.status || 'active'}${actionable ? ' status-actionable' : ''
                                }${full.status === 'approved' && full.validateTimetrackOption
                                    ? ' status-approved-validate-timetrack'
                                    : ''
                                }${user_type === 'member' ? ' status-no-click-for-member' : ''}`}
                            onClick={() => (showStatus ? this.handleAccept(id, full) : null)}
                        >
                            {statusText.toLowerCase() === 'pending' ||
                                !(full.status_changed_by && full.status_changed_on) ? (
                                <div className="text-capitalize">{t(statusText)}</div>
                            ) : (
                                <>
                                    <div className="text-capitalize">
                                        {full.status_changed_by &&
                                            moment(new Date(full.status_changed_on)).format(serverTimeFormat(true))}
                                    </div>
                                    <div className="text-capitalize timelogs-text-shrading">
                                        {this.props.commonStore.getUsername(full.status_changed_by)}
                                    </div>
                                </>
                            )}
                        </div>
                    </OverlayTrigger>
                )}
                {full.user_image_attachment_id && (
                    <OverlayTrigger
                        key="user-uploded-img"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{t('User Uploaded Image')}</Tooltip>}
                    >
                        <Button
                            icon_sm
                            fill
                            onClick={() =>
                                this.setState({
                                    userImageAttachmentId: full.user_image_attachment_id,
                                    isUserUploadedImg: true,
                                })
                            }
                            simple
                            icon
                        >
                            <i className="fa fa-user" />
                        </Button>
                    </OverlayTrigger>
                )}
                {full.status === 'draft' && (
                    <div className={`text-dark status-block status-${full.status || 'active'}`}>{t(statusText)}</div>
                )}
                {!hideEdit &&
                    <OverlayTrigger
                        key="timelog_edit"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {t('Edit Timesheet')}
                            </Tooltip>
                        }
                    >
                        <Button icon_sm fill
                            onClick={() => this.handleTableButtonAction(id, 'edit')}>
                            {(userId === full.user_id || allow) && <img src={Edit} alt={'edit'} />}
                            {userId !== full.user_id && !allow && <i className="fa fa-eye" />}
                        </Button>
                    </OverlayTrigger>
                }
                {(userId === full.user_id || allow) && (
                    <OverlayTrigger
                        key="timelog_delete"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {full.status === 'approved' && config.integration_details.product ?
                                    t('Please reset approved hours before deleting them permanently from Time & Control')
                                    : t('Delete Timesheet')
                                }.
                            </Tooltip>
                        }
                    >
                        <Button
                            icon_sm_delete
                            fill
                            onClick={() => this.handleTableButtonAction(id, 'remove')}
                            disabled={(full.status === 'approved' && config.integration_details.product)}
                        >
                            <img src={Delete} alt="delete" />
                        </Button>
                    </OverlayTrigger>
                )}
            </div>
        );
    };

    rowHasAnomaly(row) {
        const { anomaly, data } = row;
        return !data?.toChangedById && (anomaly?.start?.is_early || anomaly?.start?.is_late || anomaly?.start?.is_offsite || anomaly?.end?.is_early || anomaly?.end?.is_late || anomaly?.end?.is_offsite);
    }

    renderExpandedContent = (row) => {
        const { t } = this.props;
        const { status, anomaly } = row;
        return (
            <Row className='gx-0'>
                <Col xs={status !== 'draft' ? 3 : 6} md={status !== 'draft' ? 2 : 4} className={status !== 'draft' ? '' : 'd-flex'}>
                    <div className='d-flex'>
                        <div>{t('Clock In')}:&nbsp;</div>
                        <div className={anomaly?.start?.is_early ? "text-warning" : anomaly?.start?.is_late ? "text-danger" : "text-success"}>
                            <i className='fa fa-clock-o' />
                            {anomaly?.start?.is_early ? t('Early') : anomaly?.start?.is_late ? t('Late') : t('On-time')}
                        </div>
                    </div>
                    <div className={status !== 'draft' ? 'd-flex' : 'ms-5 d-flex'}>
                        <div>{t('Location')}:&nbsp;</div>
                        <div className={anomaly?.start?.is_offsite ? "text-danger" : "text-success"}>
                            <i className='fa fa-map-marker' />
                            {anomaly?.start?.is_offsite ? t('Off-site') : t('On-site')}
                        </div>
                    </div>
                </Col>
                <Col xs={status !== 'draft' ? 3 : 6} md={status !== 'draft' ? 4 : 8}>
                    <div className='d-flex'>
                        <div className=''>{t('Comment')}:&nbsp;</div>
                        <div className='text-info'>{t(anomaly?.start?.comment)}</div>
                    </div>
                </Col>
                {status !== 'draft' &&
                    <>
                        <Col xs={3} md={2}>
                            <div className='d-flex'>
                                <div>{t('Clock Out')}:&nbsp;</div>
                                <div className={anomaly?.end?.is_early ? "text-warning" : anomaly?.end?.is_late ? "text-danger" : "text-success"}>
                                    <i className='fa fa-clock-o' />
                                    {anomaly?.end?.is_early ? t('Early') : anomaly?.end?.is_late ? t('Late') : t('On-time')}
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div>{t('Location')}:&nbsp;</div>
                                <div className={anomaly?.end?.is_offsite ? "text-danger" : "text-success"}>
                                    <i className='fa fa-map-marker' />
                                    {anomaly?.end?.is_offsite ? t('Off-site') : t('On-site')}
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} md={4}>
                            <div className='d-flex'>
                                <div >{`${t('Comment')}`}:&nbsp;</div>
                                <div className='text-info'>{t(anomaly?.end?.comment)}</div>
                            </div>
                        </Col>
                    </>
                }
            </Row >
        )
    }

    handleExpandAllSubRows = (is_clicked) => {
        if (is_clicked === this.props.timelogStore.filters?.showAnomalies) return;
        this.props.timelogStore.setFilter("showAnomalies", is_clicked)
        this.props.timelogStore.onFilter();
    }

    render() {
        const { loading, filters, appliedFilters, currentList } = this.props.timelogStore;
        const { user_type } = this.props.userStore.currentUser;
        const { accepting, acceptanceChanged, isUserUploadedImg, userImageAttachmentId } = this.state;
        const { config } = this.props.commonStore;
        this.localeIsEn = !!(this.props.commonStore && this.props.commonStore.locale === 'en');
        const {config : commonConfig } = this.props.commonStore;
        const showAddForm = user_type === 'member' || user_type === 'pm' || !customerFeature('pm_no_register');
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting');
        const showAnomalies = config.client && config.client.data && config.client.data.loginRules && config.client.data.loginRules.trackAnomalies;
        if (this.state.isLoading)
            return <LoadingSpinner />;
        
        const dateTimeRules = commonConfig.client && commonConfig.client.data 
                              && commonConfig.client.data.dateTimeRules ? 
                              commonConfig.client.data.dateTimeRules : false;   
        
        const weeklyMaxHoursInMins = commonConfig.client && commonConfig.client.data &&
                            commonConfig.client.data.basicRules.maxWeeklyWorkingHours > 0?
                            commonConfig.client.data.basicRules.maxWeeklyWorkingHours * 60
                            : 40 * 60;

        const weekly_total_Hours_in_mins = this.props.timelogStore.weeklyResult && 
                                           this.props.timelogStore.weeklyResult.total_weekly_hours_in_mins > weeklyMaxHoursInMins?
                                           weeklyMaxHoursInMins : this.props.timelogStore.weeklyResult?.total_weekly_hours_in_mins;
                        
        const weekly_overtime_hours = this.props.timelogStore.weeklyResult && 
                                    this.props.timelogStore.weeklyResult.total_weekly_hours_in_mins > weeklyMaxHoursInMins?
                                    this.props.timelogStore.weeklyResult.total_weekly_hours_in_mins - weeklyMaxHoursInMins : 0;
        
        const isWeeklyKey = commonConfig.client && commonConfig.client.data && commonConfig.client.data.basicRules &&
                            commonConfig.client.data.basicRules.allowWeeklyManualOvertimeRegister ?
                            commonConfig.client.data.basicRules.allowWeeklyManualOvertimeRegister : false

        const getWeeklyTotalTime = (
                user_type != 'admin' && isWeeklyKey &&
                <div className="table-list__header_v3 ">
                    <div className='header_text'>
                        <Row className={user_type === 'pm' ? 'd-flex justify-content-center' : 'd-flex justify-content-md-end' }>
                            <Col sm={9} className='text-center'>
                                <div>
                                    {' ( ' +
                                        `${shortDateFormat(this.props.timelogStore.weeklyResult && this.props.timelogStore.weeklyResult.weekStartDate, dateTimeRules)}` + ' - ' +
                                        `${shortDateFormat(this.props.timelogStore.weeklyResult && this.props.timelogStore.weeklyResult.weekEndDate, dateTimeRules)}` + ' ) '
                                    }
                                </div>
                                <div>
                                    {this.props.t("Weekly") + " " + this.props.t("Total Hours") + " : " +
                                        `${totalTimeFormat(weekly_total_Hours_in_mins || 0, dateTimeRules)}`
                                    }
                                </div>
                                <div>
                                    {this.props.t("Weekly") + " " + this.props.t("Overtime Hours") + " : " +
                                        `${totalTimeFormat(weekly_overtime_hours || 0, dateTimeRules)}`
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )

        return (
            <Fragment>
                <Modal
                    className={`ApproveModal ${currentList?.rows?.find((row) => row.id === accepting?.ids[0])?.validateTimetrackOption && accepting?.startedByWorklogButton ? 'modal right fade modal_revised' : ''}`}
                    size="lg"
                    show={!!accepting}
                    onHide={() => this.setState({ accepting: null })}
                >
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center ApproveModal__title m-0">
                            {this.props.t('Accept/reject')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            alt='close icon'
                            onClick={() => this.setState({ accepting: null })}
                        />
                    </Modal.Header>

                    <Modal.Body className="pb-1 plr-50">
                        <ModalEditor params={accepting} afterSave={() => this.afterMassAccept()} />
                    </Modal.Body>
                </Modal>
                <Modal
                    size="sm"
                    show={isUserUploadedImg}
                    onHide={() => this.setState({ isUserUploadedImg: false, userImageAttachmentId: null })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('User Uploaded Image')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                                src={`${config.UPLOADS_API_ENDPOINT}/${userImageAttachmentId}`}
                                alt=""
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.125)',
                                    borderRadius: '4px',
                                    maxWidth: '600px',
                                    maxHeight: '600px',
                                }}
                            ></img>
                        </div>
                    </Modal.Body>
                </Modal>
                <GenericList
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.timelogStore.setFilter(name, value)}
                            onFilter={() => this.props.timelogStore.onFilter()}
                            loading={loading}
                            hasSearch={user_type !== 'member'}
                            searchFilterPlaceholder={"Search by employee name"}
                            hasDateFilters={true}
                            hasProjectFilter={true}
                            hasStatusFilter={true}
                            statusOptions={statuses}
                        />
                    }
                    tableHeader={this.props.t('Working hours')}
                    columns={this.cols}
                    onSelectionChanged={(selection) => this.onSelectionChanged(selection)}
                    allowCheckboxes={showStatus}
                    forceReload={this.props.timelogStore.deleteSuccess || acceptanceChanged}
                    filters={appliedFilters}
                    lastListLoadTime={this.props.timelogStore.lastListLoadTime}
                    onChangeStatus={() => this.onMassAccept()}
                    showStatus={showStatus}
                    loading={loading}
                    requestData={(params) => this.props.timelogStore.loadList(params)}
                    pageSize={50}
                    getWeeklyTotalTime={getWeeklyTotalTime}
                    showSubRows={showAnomalies}
                    expandableRowHandler={"from"}
                    expandadedRowContent={this.renderExpandedContent}
                    hasSubRows={this.rowHasAnomaly}
                    handleExpandAllSubRows={this.handleExpandAllSubRows}
                    handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)} //handles page change
                    page={this.state.page} //new page props to maintain page from parent//
                    isPageAddedOrEdited={this.state.isPageAddedOrEdited} //to load same page after edit//
                >
                    <>
                        {(showAddForm || allow) && (
                            <Button fill wd onClick={() => this.props.onAdd()}>
                                <i className="fa fa-plus-circle fa-xl margin-right-10" /> {this.props.t('Add new')}
                            </Button>
                        )}
                    </>

                </GenericList>
            </Fragment>
        );
    }
}
export default inject(
    'timelogStore',
    'userStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(TimelogsList)))));
